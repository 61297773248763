import React from 'react'
import CabaretLayout from '../../../components/cabaretlayout'
import CabaretInfosNavigation from '../../../components/Infos/CabaretInfos/CabaretInfosNavigations'
import { StaticImage } from 'gatsby-plugin-image'

const CabaretInfosGagnants = () => (
    <CabaretLayout>
        <div className='cabaret-infos'>

        <CabaretInfosNavigation />

        <h1>Gagnant·es 2025</h1>

        <div className='cabaret-infos-inner'>
            <div className='cabaret-infos-inner-round-image'>
                <StaticImage alt='Auberge des balcons' className="max-w-[1856px] max-h-[1044px] mt-8 mb-12" src="../../../images/cabaret/gagnants.png" />
            </div>

            <h2>Prix remis - Grande finale 2025</h2>
            <div className='cabaret-infos-inner-list mb-6'>
                <ul>
                    <li><h3><b>Prix du Jury:</b> easy tiger</h3></li>
                    <li><h3><b>Prix du Public:</b> Valse Fréquence</h3></li>
                    <li><h3><b>Prix du Porte-Parole:</b> Valse Fréquence</h3></li>
                    <li><h3><b>Prix Le Pantoum:</b> easy tiger</h3></li>
                    <li><h3><b>Prix coup de coeur Télé-Québec:</b> Valse Fréquence</h3></li>
                    <li><h3><b>Prix Festival international de la chanson de Granby:</b> Crachat</h3></li>
                    <li><h3><b>Prix Domaine Forget de Charlevoix:</b> Vaëlle</h3></li>
                    <li><h3><b>Prix Le Phoque Off:</b> Valse Fréquence</h3></li>
                    <li><h3><b>Prix CISM:</b> Crachat</h3></li>
                    <li><h3><b>Prix Mixbus:</b> Crachat</h3></li>
                    <li><h3><b>Prix Greg Kitzler | La Shed, relations de presse:</b> Valse Fréquence</h3></li>
                    <li><h3><b>Prix l'Ampli de Québec:</b> Vaëlle</h3></li>
                </ul>
            </div>

            <h2>Prestations rémunérées dans les programmations suivantes</h2>
            <div className='cabaret-infos-inner-list'>
                <ul>
                    <li><h3><b>Prix Festival en Chanson de Petite-Vallée:</b> Valse Fréquence</h3></li>
                    <li><h3><b>Prix Festival de la Chanson de Tadoussac:</b> easy tiger</h3></li>
                    <li><h3><b>Prix Vieux Bureau de Poste (Lévis):</b> easy tiger</h3></li>
                    <li><h3><b>Prix Le Zaricot (St-Hyacinthe):</b> Fleur de peau</h3></li>
                    <li><h3><b>Prix Le Minotaure:</b> Valse Fréquence</h3></li>
                    <li><h3><b>Prix Auberge Festive Sea Shack (Ste Anne des monts):</b> easy tiger</h3></li>
                    <li><h3><b>Prix Cabane Panache:</b> easy tiger</h3></li>
                    <li><h3><b>Prix Bistro des Balcons:</b> Crachat</h3></li>
                    <li><h3><b>Prix Festival Off:</b> easy tiger</h3></li>
                    <li><h3><b>Prix Festival Colline:</b> Valse Fréquence</h3></li>
                    <li><h3><b>Prix POP Montréal:</b> Vaëlle</h3></li>
                    <li><h3><b>Prix Festival de la poutine:</b> Crachat</h3></li>
                    <li><h3><b>Prix St-Roch XP:</b> Valse Fréquence</h3></li>
                </ul>
            </div>

        </div>

        </div>
    </CabaretLayout>
)

export default CabaretInfosGagnants
